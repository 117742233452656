import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class WebrequestService {
  readonly ROOT_URL;
  constructor(private http: HttpClient) { 
    //this.ROOT_URL ='http://localhost:3000'
    //this.ROOT_URL= 'https://2-ndre434kdfjdf.devbluedevs.com'   //Servidor nuevo
    this.ROOT_URL = 'https://jymapi.devbluedevs.com:3008';
  }
  get(uri: string, header:string) {
    
    return this.http.get(`${this.ROOT_URL}/${uri}`);
  }

  post(uri: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL}/${uri}`, payload);
  }
}
//SCEBM